import { ApiResponse } from '../models/ApiResponse';
import { SignatureDetails, SignResponse } from '../models/ClientForm';
import {
  DistributionCreateRequest,
  DistributionInfoForContactResponse,
  DistributionMember,
  DistributionResponse,
  DistributionUpdateRequest,
} from '../models/Distribution';
import BaseService from './BaseService';

export default class DistributionService extends BaseService {
  public static createDistribution(distribution: DistributionCreateRequest): Promise<ApiResponse<DistributionResponse>> {
    return this.post('/v1/distribution', distribution);
  }

  public static updateDistribution(distributionId: string, payload: DistributionUpdateRequest): Promise<ApiResponse<DistributionResponse>> {
    return this.put(`/v1/distribution/${distributionId}`, payload);
  }

  public static getPublicDistribution(): Promise<ApiResponse<DistributionInfoForContactResponse>> {
    return this.get('/v1/distribution/public/client-form');
  }

  public static acknowledge(
    clientFormId: string,
    distributionId: string,
    remark: string,
    signatureDetails?: SignatureDetails,
  ): Promise<ApiResponse<SignResponse>> {
    return this.post(`/v1/distribution/acknowledge`, { clientFormId, distributionId, remark, ...(signatureDetails ?? {}) });
  }

  public static sendReminder(member: DistributionMember): Promise<ApiResponse<void>> {
    return this.post(`/v1/distribution/${member.distributionId}/remind/${member.memberId}`);
  }
}
